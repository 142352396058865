import React, { FC } from 'react';
import './Quote.scss';
import { IQuoteProps } from '../../../../types/types';

const Quote: FC<IQuoteProps> = (props) => {
    return (
        <div data-testid="quote" className="quote">
            <p className="quote__text">{props.children}</p>
        </div>
    );
};

export default Quote;
