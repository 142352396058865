import React, { FC } from 'react';
import SpectarLayout from '../../components/PagesLayouts/SpectarLayout';
import './SpectarCaseStudy.scss';
import SectionTitle from '../../components/common/SectionTitle';
import TextDescription from '../../components/common/TextDescription';
import Quote from '../../components/pagesComponents/CaseStudy/Quote';
import YuriiDrozd from '../../assets/img/aboutUs/staff/PM/YuriiDrozd.jpeg';
import DmytriiStepanenko from '../../assets/img/aboutUs/staff/PM/DmytriiStepanenko.jpeg';
import OleksandrLomakovskyi from '../../assets/img/aboutUs/staff/Developers/OleksandrLomakovskyi.jpeg';
import GlebGerasimenko from '../../assets/img/aboutUs/staff/Developers/GlebGerasimenko.jpeg';
import AnastasiiaShyshak from '../../assets/img/aboutUs/staff/QA/AnastasiiaShyshak.jpeg';
import AnastasiiaIlina from '../../assets/img/aboutUs/staff/Developers/AnastasiiaIlina.jpeg';
import Links from '../../components/pagesComponents/CaseStudy/Links';
import PeolpeWhoMade from '../..//components/pagesComponents/CaseStudy/PeolpeWhoMade';
import { TOurTeam, TText } from '../../types/types';
import FixedButton from '../../components/common/FixedButton';
import { useScrollToTop } from '../../hooks/useScrollToTop';

const SpectarCaseStudy: FC = () => {
    const outcomesText: TText = [
        {
            id: 0,
            text:
                'Spectar and Blackbirders made a holistic effort to build a truly pioneering product and keep doing it side-by-side. Moreover, Spectar management decided to grow the off-site team. It’s more than 10 Blackbirders push the boundaries alongside Spectar crew.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'Blackbird joined the development of Spectar application for HoloLens 2 in addition to furthers development and maintenance of Spectar Cloud app suite and HoloLens 1 app.',
            margin: 'mb',
        },
    ];
    const ourTalanted: TOurTeam = [
        { id: 0, name: 'Yurii D.', position: 'CEO', img: YuriiDrozd },

        { id: 1, name: 'Dmytrii S.', position: 'Account Manager', img: DmytriiStepanenko },
        {
            id: 2,
            name: 'Oleksandr L.',
            position: 'Senior Developer',
            img: OleksandrLomakovskyi,
        },
        {
            id: 3,
            name: 'Gleb G.',
            position: 'Senior Developer',
            img: GlebGerasimenko,
        },
        {
            id: 4,
            name: 'Anastasiia S.',
            position: 'Senior QA',
            img: AnastasiiaShyshak,
        },
        {
            id: 5,
            name: 'Anastasiia Ilina',
            position: 'Senior Java Engineer',
            img: AnastasiiaIlina,
        },
    ];

    const beginingStart: TText = [
        {
            id: 0,
            text:
                'At the time the client told us about their development needs, they already had a nicely working prototype of their HoloLens application. Their on-site team did well on it. However, their brainwidth was fully loaded with further development of AR application.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'HoloLens application is a big part of their software solutions. Augmented reality is the innovation that they worked hard to bring to the entire construction industry. However, in order to make this innovation working smoothly for the majority, there were other demands to address. Among them was a clear request in other supportive software solutions including the cloud ones.',
            margin: 'mb',
        },
    ];

    const beginingEnd: TText = [
        {
            id: 0,
            text:
                'Straight from the beginning, Spectar managers made a decision that they need a reliable partner to whom they could entirely outsource product development as a turnkey service. Fully managed IT services model was chosen as an obvious and the most suitable engagement choice. It remains so nowadays.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'We started small as a group of 2 engineers, 1 system architect and 1 QA dived straight to working on Spectar Cloud. ',
            margin: 'mb',
        },
        {
            id: 2,
            text:
                'Spectar Cloud is the supportive solution meant to be that perfect link between in-office employees, BIM engineers and field workers on a construction site. Among other important features are such things as project organization and management; BIM files storing and converting for HoloLens usage; easy version history management for uploaded BIM models; and, of course, synchronization with HoloLens smartglasses used on a jobsite.',
            margin: 'mb',
        },
        {
            id: 3,
            text:
                'The application version for HoloLens 2 provides even more perks. For instance, it supports large BIM models and multiple model overlay. We did a very custom BIM model convertor that others could only dream about. That’s the most pioneering part of the things we accomplished here.',
            margin: 'mb',
        },
        {
            id: 4,
            text:
                "In addition to that Spectar Cloud was made to support multiple end-users' organization roles, and to provide a clear way to make payments and control customers' subscriptions.",
            margin: 'mb',
        },
        {
            id: 5,
            text:
                'That what Spectar Cloud eventually become nowadays, after we had started working on it. Early versions of the cloud app have no clear role and payment management, not posts BIM files were supported, and the general project organization logic was lacking lots of good stuff.',
            margin: 'mb',
        },
        {
            id: 6,
            text:
                'Nevertheless, Blackbirders hand-by-hand with Spectar team overcame a lot of other technical obstacles and limitations. Especially the ones related to developing native applications for Microsoft HoloLens of both generations.',
            margin: 'mb',
        },
    ];

    const generationsStart: TText = [
        {
            id: 0,
            text: 'It might not sound like a big achievement but it’s really a tremendous one.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'You simply will not be able to quickly build something similar to Spectar SaaS products by utilizing market-available 3rd party solutions (true for 2017-2021). You’ll be stuck on BIM model conversion and processing stage. Let us explain to you the whole picture and the product story really quickly.',
            margin: 'mb',
        },
        {
            id: 2,
            text:
                'Anthony, Spectar CTO, assembled the first prototype which was warmly welcomed by big construction market players. However, the early prototype handled plain GLB models.',
            margin: 'mb',
        },
        {
            id: 3,
            text:
                'HoloLens was chosen by Spectar team as the best hardware and software platform that meets posts the industry requirements. Especially due to its promising easiness of further product certification for field use. You may point that there are Magic Leap goggles but they are not much suitable for professionals on a construction site (for inside office only). In addition to that, it’s not a secret that enterprises are heavily using Microsoft products.',
            margin: 'mb',
        },
        {
            id: 4,
            text:
                'So everything above laid out the foundation of the future technology stack for Spectar augmented reality software for construction.',
            margin: 'mb',
        },
    ];

    const generationsMiddle: TText = [
        {
            id: 0,
            text:
                'Spectar team made a decision to trust Blackbird as their own on-site team. We had a green light to plan and implement the application architecture of our choice',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'Microservice architecture was chosen from the beginning. However, as we moved forward we managed to change a certain app service providers. Eventually, we decide not to build the SaaS product entirely dependable from Microsoft App Services.',
            margin: 'mb',
        },
        {
            id: 2,
            text:
                'We are using a lot of Azure products because they really do the job for the particular enterprise AR software we are working on. However, the back end for the Spectar SaaS product was written on Java and the front end on React. Oh, and the market best BIM model converter utilizes Python with some Java-based libraries. You will learn more about the converter later in this section.',
            margin: 'mb',
        },
        {
            id: 3,
            text:
                'Azure Virtual Machines service was used to deploy posts the microservices for the early first version of the back end. The further ones were deployed on Docker with Kubernetes (K8s) Container Orchestration. Meanwhile, the front end was and is still run on Microsoft Azure Web App for Containers.',
            margin: 'mb',
        },
        {
            id: 4,
            text:
                'Such an architecture planning allows us to quickly port Spectar AR application to any smartglasses with no headache. It means we are not limited to just Microsoft HoloLens AR smartglasses, and open for any promising alternative that would hit a market.',
            margin: 'mb',
        },
        {
            id: 5,
            text:
                'Nevertheless, posts the above are not sci-fi technologies but BIM model converter we developed entirely from the ground up is that tech miracle. It handles posts the industry popular workflows like Revit, AutoCAD, Navis.',
            margin: 'mb',
        },
        {
            id: 6,
            text:
                'By the way, Spectar Cloud supported IFC model format solely in its early versions. If you are from the industry, you know that whatever SaaS product you are looking to build, you’ll have to use Autodesk Forge APIs and services. Especially, you’ll be forced to use BIM 360 API as there are no other reliable 3rd party alternatives available (true for 2017-2021). However, the BIM conversion done via Autodesk services wasn’t the one we need.',
            margin: 'mb',
        },
        {
            id: 7,
            text:
                'Unlike other large outsourcing players, we do not practice up-selling our clients to develop their own custom solutions while the market is full of solid and affordable solutions that can be quickly used. It was a rare case when such a custom solution was a necessity not a luxury.',
            margin: 'mb',
        },
    ];

    const generationsEnd: TText = [
        {
            id: 0,
            text:
                'So we created such BIM model conversion and processing microservise, and even developed REST API for it. We promptly added support of posts HoloLens 2 capabilities at the beginning of 2020. IFC to GLB is also both fast and smooth.',
            margin: 'mb',
        },
        {
            id: 1,
            text:
                'The latest BIM model conversion and processing microservise able to digest large models with multiple model overlays built-in. So you see your 3D models live with posts the details and labels as instantly as you move around a jobsite no matter in what direction.',
            margin: 'mb',
        },
        {
            id: 2,
            text:
                'Spectar Cloud UX and UI was also redesigned by Blackbird team, so Spectar clients could enjoy a superior software not only on HoloLens but in their web browsers too.',
            margin: 'mb',
        },
        {
            id: 3,
            text:
                'Since we cooperate as a turnkey fully managed IT services, we also took care of such internal operations flows as customer onboarding and support, subscription management, etc. Spectar sales and customer support teams have their own tools to take care of posts important business operations.',
            margin: 'mb',
        },
    ];

    useScrollToTop();

    return (
        <SpectarLayout>
            <div className="statflo-case-study__text">
                <SectionTitle type="primary" title="A great start" />
                {beginingStart.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}
                <Quote>
                    You simply will not be able to quickly build something similar to Spectar SaaS
                    products by utilizing market-available 3rd party solutions.{' '}
                </Quote>
                {beginingEnd.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}
                <SectionTitle
                    type="primary"
                    title="Single Spectar cloud to support all HoloLens generations"
                />
                {generationsStart.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}

                <Quote>
                    We created BIM model conversion and processing microservise with its own REST
                    API that others could only dream about.
                </Quote>

                {generationsMiddle.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}
                <Quote>
                    Not limited to Microsoft HoloLens smartglasses. The product architecture allows
                    us to quickly port Spectar AR application to any promising Microsoft
                    alternatives.
                </Quote>
                {generationsEnd.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}
                <SectionTitle type="primary" title="Ongoing outcomes" />
                {outcomesText.map((text) => (
                    <TextDescription key={text.id} subtype={text.margin}>
                        {text.text}
                    </TextDescription>
                ))}
                <Links />
            </div>
            <PeolpeWhoMade page="/spectar-case-study/full-credits/" ourTalanted={ourTalanted} />
            <FixedButton />
        </SpectarLayout>
    );
};

export default SpectarCaseStudy;
