import React, { FC } from 'react';
import './SmallPhotoIcon.scss';
import { ISmallPhotoIconProps } from '../../../../types/types';

const SmallPhotoIcon: FC<ISmallPhotoIconProps> = (props) => {
    return (
        <div
            data-testid="small-photo-icon"
            className={`small-photo-icon__container small-photo-icon__container--${props.margin}`}
        >
            {props.ourTalanted.map((talant) => (
                <div className={`small-photo-icon small-photo-icon--${props.type}`} key={talant.id}>
                    <div className="small-photo-icon__wrapper">
                        <img src={talant.img} alt="" className="small-photo-icon__img" />
                    </div>
                    <div className="small-photo-icon__description">
                        <span className="small-photo-icon__name">{talant.name}</span>
                        <span className="small-photo-icon__position">{talant.position}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SmallPhotoIcon;
