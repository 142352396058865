import React, { FC } from 'react';
import './PeolpeWhoMade.scss';
import Span from '../Span';
import Button from '../../../common/Button';
import SmallPhotoIcon from '../../AboutUs/SmallPhotoIcon';
import arrRight from '../../../../assets/img/arr-right.svg';
import { Link } from 'gatsby';
import { TPeopleMade } from '../../../../types/types';

const PeolpeWhoMade: FC<TPeopleMade> = (props) => {
    const ourTalanted = props.ourTalanted;

    return (
        <div data-testid="people-who-made" className="peolpe-who-made">
            <div className="peolpe-who-made__container">
                <Span type="peoples" subtype="mt">
                    People who made it a reality
                </Span>
                <SmallPhotoIcon margin="no-flex" ourTalanted={ourTalanted} type="mr-10" />
                <div className="peolpe-who-made__container-btn">
                    <Link to={props.page}>
                        <Button type="watch" desc="See full credits" icon={arrRight}>
                            See full credits
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PeolpeWhoMade;
